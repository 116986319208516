export default {
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    send(){
      const data = {
        fk_operator: this.$store.getters.me?.id,
        status: 'closed'
      }
      this.axios
        .put(this.$store.getters.getTicket(this.$route.params.id),data)
        .then(() => {
          this.dialog = false
          this.$awn.success(this.$t('messages.ticket_completed'))
          this.$emit('closeChat')
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}