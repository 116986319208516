import "./Chat.css";
import messagesChat from '../../components/chat/Messages.vue'
import CompleteChat from '../../components/chat/CompleteChat.vue'

export default {
  components: {
    messagesChat,
    CompleteChat
  },
  data() {
    return {
      content: '',
      chat: {},
      disabled: false,
      username: this.$store.getters?.me?.username || "invitado",
      chat_files: [],
      loading: false
    }
  },
  methods: {
    sendMessage() {
      if (this.content == '') {
        //this.alert.text = this.$t('empty_message')
      }
      else {
        let chat = null
        if (this.chat_files.length == 0) {
          chat = {
            'chat': this.chat.pk,
            'comment': this.content,
          }
        }
        else {
          chat = new FormData()
          chat.append('chat', this.chat.pk)
          chat.append('comment', this.content)
          for (let attch of this.chat_files) {
            chat.append('message_attch', attch)
          }
        }
        this.axios.post(this.$store.getters.getMessages(), chat)
          .then((response) => {
            if (response.status == 201 && response.statusText == "Created") {
              this.chat_files = []
              this.content = ''
              this.$store.commit('socketMessage', { 'type': 'chat', 'content': response.data })
            }
          })
          .catch((error) => {
            console.error(error.response)
          })
      }
    },
    loadChats() {
      this.axios
        .get(this.$store.getters.getChats(), {
          params: {
            ticket_id: this.$route.params.id 
          }
        })
        .then(response => {
          this.chat = response.results[0]
          this.loadMessages(this.chat.pk)
          if(this.chat.ticket_status === 'closed'){
            this.disabled = true
          }
        })
        .finally(() => {
          if (this.$store.getters.getIsloading) {
            this.$store.dispatch("actionLoading")
          }
          this.loading = false
        })
    },
    loadMessages(pk) {
      this.$refs.messagesChat.loadMessages(pk)
    },
    checkMessage(message) {
      if (message.type == 'chat') {
        if (message.content.chat == this.chat.pk) {
          this.$refs['messagesChat'].appendMessage(message.content)
        }
      }
    },
    markAsRead(pk) {
      this.axios
        .post(this.$store.getters.markMessageRead(pk))
        .then(response => {
          if (response.status == 200) {
            this.loadChats()
          }
        })
    },
    imageClick() {
      this.$refs.image.click()
    },
    onFilePicked(e) {
      this.chat_files = []
      for (let item of e.target.files) {
        this.chat_files.push(item)
      }
    },
    removeAttch(index) {
      this.chat_files.splice(index, 1)
    }
  },
  mounted() {
    this.loadChats()
    /*this.$store.subscribe((mutation) => {
      if (mutation.type == 'SOCKET_ONMESSAGE') {
        this.checkMessage(mutation.payload)
      }
    })*/
  },
  watch: {
    '$store.getters.getSocketMessage': function() {
      this.checkMessage(this.$store.getters.getSocketMessage)
    }
  }
}