export default{
  name: 'messages',
  data(){
    return {
      messages:[],
      username:this.$store.getters?.me?.username || "invitado",
      loader: null,
      loading: false,
      more_messages:false,
      current_chat: 0,
    }
  },
  methods:{
    loadMessages(chat_pk){
      this.current_chat = chat_pk
      this.messages = []
      this.axios
      .get(this.$store.getters.getMessages(),{
          params: {
            chat: chat_pk
          }
      })
      .then(response => {
        this.next = response.next
        this.checkMsg(this.next)
        let results = response.results.reverse()
        for(let message of results){
          this.messages.push({'user':message.username,'content':message.comment,'attachment':message.message_attch})
        }
      })
    },
    appendMessage(message){
      this.messages.push({'user':message.username,'content':message.comment,'attachment':message.message_attch})
    },
    loadMore(){
      this.axios
      .get(this.next,{
          params: {
            chat: this.current_chat
          }
      })
      .then(response => {
        this.next = response.next
        this.checkMsg(this.next)
        let results = response.results.reverse()
        for(let message of results){
          this.messages.unshift({'user':message.username,'content':message.comment,'attachment':message.message_attch})
        }
      })
    },
    checkMsg(next){
      if(next){
        this.more_messages = true
      }
      else{
        this.more_messages = false
      }
    }
  }
  /*watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => (this[l] = false), 3000)
      this.loader = null
    }
  }*/
}